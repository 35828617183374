import React from "react"
import * as style from "./DateLabel.module.scss"

/**
 * Returns a styled date label for use in a relatively positioned container
 * @param {sting} date
 */

export const DateLabel = ({ date }) => {
  const displayDate = new Date(date).toLocaleDateString("eng-GB", {
    year: "numeric",
    month: "short",
    day: "numeric",
  })

  return (
    <div className={style.dateContainer}>
      <p
        className={style.date}
        dangerouslySetInnerHTML={{
          __html: displayDate,
        }}
      />
    </div>
  )
}
