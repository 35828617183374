import React from "react"
import { Link } from "../../components/UI/Common"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Card } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { DateLabel } from "../UI/DateLabel"
import classNames from "classnames"
import * as style from "./LatestPosts.module.scss"

const LatestPosts = ({ postsToRender, trioWrap }) => {

  return (
    <Row className={trioWrap ? articlesTrioWrapBlock : articlesBlock}>
      {postsToRender.map((post, index) => {
        const postContent = post.postsLayouts.postObject[0]

        if(!postContent.mainImage) {
          // TODO: temp fix for post component expansion
          return null
        }

        return (
          <Col
            className={style.articleCol}
            sm={12}
            md={9}
            lg={trioWrap ? 8 : 6}
            xl={4}>
            <article>
              <Card className={style.card}>
                <div className={cardImgClass}>
                  <DateLabel date={post.date} />
                  <GatsbyImage
                    className={style.gatsbyImgDiv}
                    image={getImage(postContent.mainImage.localFile)}
                    alt={postContent.mainImage.altText ? postContent.mainImage.altText : "Latest post image"}
                  />
                </div>
                <Card.Body>
                  <h2
                    className={style.postTitle}
                    dangerouslySetInnerHTML={{
                      __html: postContent.postTitle,
                    }}
                  />
                  <p
                    className={style.postBlurb}
                    dangerouslySetInnerHTML={{
                      __html: postContent.blurb,
                    }}
                  />
                  <p>
                    <Link link={post.uri}>Read More</Link>
                  </p>
                </Card.Body>
              </Card>
            </article>
          </Col>
        )
      })}
    </Row>
  )
}
export default LatestPosts

const articlesTrioWrapBlock = classNames(
  "d-flex",
  "justify-content-center",
  "align-items-center",
  "w-100",
  "px-3",
  "px-md-0"
)
const articlesBlock = classNames(
  "d-flex",
  "justify-content-center",
  "justify-content-lg-start",
  "px-3",
  "px-md-0"
)

const cardImgClass = classNames("position-relative")
