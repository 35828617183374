import React, { useState, useEffect } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import LatestPosts from "../AcfPostBlocks/LatestPosts"
import { DateLabel } from "../UI/DateLabel"
import classNames from "classnames"
import * as style from "./BlogPostList.module.scss"
import {buttonCls, outlinedCls} from '../UI/Common/LinkButton/LinkButton.module.scss'
import { dateComparator } from "../../utils/dateUtils"
export const fragment = graphql`
  fragment BlogPostListFragment on WpPage_Flexlayouts_FlexibleLayouts_BlogPostList {
    pageTitle
    description
    button {
      title
      url
    }
  }
`

const BlogPostList = ({ pageTitle, description, button }) => {
  const Posts_QUERY = graphql`
    query GET_POST_LIST_ITEMS {

       allWpPost(limit: 50) {
          nodes {
            ...PostDataQuery
          }
        }
      }

  `
  const [next, setNext] = useState(6)
  const [btnDisabled, setbtnDisabled] = useState(false)
  const data = useStaticQuery(Posts_QUERY)
  let posts = data.allWpPost.nodes
  const postByDate = posts.sort(dateComparator)
  const featuredPost = postByDate[0]
  const featured = featuredPost?.postsLayouts?.postObject[0]
  const postsList = postByDate.slice(1)
  const [postsToShow, setPostsToShow] = useState(postByDate.slice(1,4))
  const postsPerLoad = 6
  let accumulatingPostToShow = []
  const getPostsOnLoad = (start, end) => {
    const morePostsOnLoad = postsList.slice(start, end)
    accumulatingPostToShow = [...postsToShow, ...morePostsOnLoad]
    setPostsToShow(accumulatingPostToShow)
  }
  if (featured) {
    const isMorePosts = () => {
      if (accumulatingPostToShow.length < postsList.length) {
        setbtnDisabled(false)
      } else {
        setbtnDisabled(true)
      }
    }

    const getMorePosts = () => {
      getPostsOnLoad(next, next + postsPerLoad)
      setNext(next + postsPerLoad)
      isMorePosts()
    }

    return (
        <Container className={style.container}>
          <Row className={headerRowClass}>
            <Col>
              <header className={headerClass}>
                <h1
                    className={pageTitleClass}
                    dangerouslySetInnerHTML={{
                      __html: pageTitle,
                    }}
                />
                <p
                    className={style.pageBlurb}
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                />
              </header>
            </Col>
          </Row>
          {featured.mainImage && (
              <article>
                <Row className={style.featuredArticle}>
                  <Col className={style.featuredImgCol} sm={12} lg={6}>
                    <DateLabel date={featuredPost.date}/>
                    <GatsbyImage
                        className={style.featuredImage}
                        image={getImage(featured.mainImage.localFile)}
                        alt={featured.mainImage.altText}
                    />
                  </Col>
                  <Col className={style.featuredTextCol} sm={12} lg={6}>
                    <div className={style.featuredTextContainer}>
                      <h2
                          className={style.featuredPostTitle}
                          dangerouslySetInnerHTML={{
                            __html: featured.postTitle,
                          }}
                      />
                      {featured.blurb && (
                          <p
                              className={style.featuredPostBlurb}
                              dangerouslySetInnerHTML={{
                                __html: featured.blurb,
                              }}
                          />
                      )}
                      {featured.description && (
                          <p
                              dangerouslySetInnerHTML={{
                                __html: featured.description,
                              }}
                          />
                      )}
                      <p>
                        <Link to={featuredPost.slug}>Read More</Link>
                      </p>
                    </div>
                  </Col>
                </Row>
              </article>
          )}
          <Row className={style.lastestPostsContainer}>
            <Col className={postListSectionClass}>
             {postsToShow && <LatestPosts postsToRender={postsToShow}/>}
            </Col>
          </Row>
          <Row>
            <Col className={footerClass}>
              <button
                  className={
                    btnDisabled === true ? buttonDisabledClass : `${buttonCls} ${outlinedCls}`
                  }
                  type="button"
                  onClick={getMorePosts}>
                More articles
              </button>
            </Col>
          </Row>
        </Container>
    )
  } else {
    return (
        <Container className={style.container}>
          <Row className={headerRowClass}>
            <Col>
              <header className={headerClass}>
                <h1
                    className={pageTitleClass}
                    dangerouslySetInnerHTML={{
                      __html: pageTitle,
                    }}
                />
                <p
                    className={style.pageBlurb}
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                />
              </header>
            </Col>
          </Row>
        </Container>
    )

  }
}

/*
 * STYLES
 */

const headerRowClass = classNames(
  "d-flex",
  "justify-content-center",
  "my-4",
  "m-4"
)
const headerClass = classNames(
  "d-flex",
  "flex-column",
  "align-items-center",
  "text-center"
)
const pageTitleClass = classNames("text-uppercase")

const postListSectionClass = classNames()
const footerClass = classNames("d-flex", "justify-content-center", "py-large")

// Paginator
const buttonDisabledClass = classNames("d-none")

export default BlogPostList
