// extracted by mini-css-extract-plugin
export var articleCol = "LatestPosts-module--articleCol--76a0a";
export var bgGrey100 = "LatestPosts-module--bg-grey-100--7a9d1";
export var bgGrey150 = "LatestPosts-module--bg-grey-150--7d628";
export var bgGrey200 = "LatestPosts-module--bg-grey-200--09d03";
export var bgGrey300 = "LatestPosts-module--bg-grey-300--e03a8";
export var bgGrey400 = "LatestPosts-module--bg-grey-400--77f24";
export var bgGrey500 = "LatestPosts-module--bg-grey-500--d6ef2";
export var bgGrey600 = "LatestPosts-module--bg-grey-600--df2ae";
export var bgGrey700 = "LatestPosts-module--bg-grey-700--c4d38";
export var bgGrey800 = "LatestPosts-module--bg-grey-800--24ca0";
export var bgGrey900 = "LatestPosts-module--bg-grey-900--24143";
export var card = "LatestPosts-module--card--857fe";
export var gatsbyImgDiv = "LatestPosts-module--gatsbyImgDiv--a44cc";
export var postBlurb = "LatestPosts-module--postBlurb--42706";
export var postTitle = "LatestPosts-module--postTitle--dff94";
export var textGrey100 = "LatestPosts-module--text-grey-100--07f0d";
export var textGrey150 = "LatestPosts-module--text-grey-150--4c3dc";
export var textGrey200 = "LatestPosts-module--text-grey-200--7920a";
export var textGrey300 = "LatestPosts-module--text-grey-300--49484";
export var textGrey400 = "LatestPosts-module--text-grey-400--7255e";
export var textGrey500 = "LatestPosts-module--text-grey-500--30ee8";
export var textGrey600 = "LatestPosts-module--text-grey-600--82859";
export var textGrey700 = "LatestPosts-module--text-grey-700--0a2dc";
export var textGrey800 = "LatestPosts-module--text-grey-800--ac182";
export var textGrey900 = "LatestPosts-module--text-grey-900--dc8ad";