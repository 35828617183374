export const  parseIsoDatetime = (dtstr) => {
  const dt = dtstr.split(/[: T-]/).map(parseFloat);
  return new Date(dt[0], dt[1] - 1, dt[2], dt[3] || 0, dt[4] || 0, dt[5] || 0, 0);
}

export const formatDateToLocalTime = (date) => {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };

  return date.toLocaleDateString("en-GB", options)
}

export const dateComparator = (a, b) => {
  let DateA = new Date(a.date);
  let DateB = new Date(b.date);
  if (DateA > DateB) {
      return -1;
  } else if (DateA < DateB) {
      return 1;
  } else {
      return 0;
  }
}