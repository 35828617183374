// extracted by mini-css-extract-plugin
export var bgGrey100 = "DateLabel-module--bg-grey-100--32af7";
export var bgGrey150 = "DateLabel-module--bg-grey-150--9b15f";
export var bgGrey200 = "DateLabel-module--bg-grey-200--f4fc6";
export var bgGrey300 = "DateLabel-module--bg-grey-300--b0516";
export var bgGrey400 = "DateLabel-module--bg-grey-400--379a4";
export var bgGrey500 = "DateLabel-module--bg-grey-500--f2256";
export var bgGrey600 = "DateLabel-module--bg-grey-600--c74f6";
export var bgGrey700 = "DateLabel-module--bg-grey-700--71e64";
export var bgGrey800 = "DateLabel-module--bg-grey-800--4a7b2";
export var bgGrey900 = "DateLabel-module--bg-grey-900--4f19d";
export var date = "DateLabel-module--date--f64a0";
export var dateContainer = "DateLabel-module--dateContainer--dd4ad";
export var font18 = "DateLabel-module--font-18--b0964";
export var font500 = "DateLabel-module--font-500--14dda";
export var gray600 = "DateLabel-module--gray-600--3be52";
export var secondary = "DateLabel-module--secondary--bf0af";
export var textGrey100 = "DateLabel-module--text-grey-100--159c3";
export var textGrey150 = "DateLabel-module--text-grey-150--36497";
export var textGrey200 = "DateLabel-module--text-grey-200--2525a";
export var textGrey300 = "DateLabel-module--text-grey-300--84fe9";
export var textGrey400 = "DateLabel-module--text-grey-400--52216";
export var textGrey500 = "DateLabel-module--text-grey-500--38678";
export var textGrey600 = "DateLabel-module--text-grey-600--1db5f";
export var textGrey700 = "DateLabel-module--text-grey-700--bb405";
export var textGrey800 = "DateLabel-module--text-grey-800--8efe1";
export var textGrey900 = "DateLabel-module--text-grey-900--ab41e";